import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import style from './notification.module.css';
import success from '../../assets/Success.png';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../loading/Loading';
import CreatableSelect from 'react-select/creatable';
import { getOneNotification, updateNotification } from '../../services/NotificationService';

const NotificationUpdateForm = ({ setHeading }) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ title: '', g_title: '', g_message: '', message: '' });
    const [error, setError] = useState('');
    const user = JSON.parse(localStorage.getItem('userdata'));
    const [form, setForm] = useState({
        created_by: user?.id,
        title: '',
        g_title: '',
        g_message: [],
        message: [],
        time: '',
    });
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [messageOptions, setMessageOptions] = useState([]);
    const [germanMessageOptions, setGermanMessageOptions] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [selectedGermanMessage, setSelectedGermanMessage] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        setErrors({ title: '', g_title: '', g_message: '', message: '' });
        getOneNotificationData();
        setHeading('Update Notification');
    }, []);

    const getOneNotificationData = async () => {
        try {
            setLoading(true);
            const response = await getOneNotification({ id });
            setData(response?.data);

            setForm({
                ...form,
                title: response?.data?.title || '',
                g_title: response?.data?.g_title || '',
                g_message: response?.data?.g_message || '',
                time: response?.data?.time || ''
            });

            const messageArray = response?.data?.message || [];
            setMessageOptions(messageArray.map(msg => ({
                label: msg.text,
                value: msg.text,
                selected: msg.selected,
                id: msg._id
            })));

            const selectedMsg = messageArray.find(msg => msg.selected);
            if (selectedMsg) {
                setSelectedMessage({
                    label: selectedMsg.text,
                    value: selectedMsg.text
                });
            }

            const germanMessageArray = response?.data?.g_message || [];
            setGermanMessageOptions(germanMessageArray.map(msg => ({
                label: msg.text,
                value: msg.text,
                selected: msg.selected,
                id: msg._id
            })));

            const selectedGermanMsg = germanMessageArray.find(msg => msg.selected);
            if (selectedGermanMsg) {
                setSelectedGermanMessage({
                    label: selectedGermanMsg.text,
                    value: selectedGermanMsg.text
                });
            }

            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const setField = (field, value) => {
        setForm(prevForm => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    const validateForm = () => {
        const { title, g_title, time } = form;
        const newErrors = {};

        if (!title || title === "") newErrors.title = "Title field is required.";
        if (!g_title || g_title === "") newErrors.g_title = "German title field is required.";
        if (!selectedMessage) newErrors.message = "At least one message is required.";
        if (!selectedGermanMessage) newErrors.g_message = "At least one German message is required.";
        if (
            (data?.day !== "everyday" && data.type !== "Special Events & Content" && (!time || time === "")) ||
            (data?.day === "1st Sunday" && data.type === "Special Events & Content" && (!time || time === ""))
        ) {
            newErrors.time = "Time field is required.";
        }

        return newErrors;
    };

    const handleOnClose = () => {
        setForm({
            created_by: user?.id,
            title: '',
            g_title: '',
            g_message: '',
            message: '',
            time: ''
        });
        setErrors({ title: '', g_title: '', g_message: '', message: '' });
        navigate('/notifications');
    };
    const handleOnSubmit = async () => {
        const newErrors = validateForm();
        setLoading(true);

        // Filter the selected messages from the messageOptions array
        const updatedMessage = messageOptions?.map(option => ({
            text: option?.value,
            selected: option?.value === selectedMessage?.value  // Set selected to true for the selected message
        }));

        const isSelectedMessagePresent = updatedMessage?.some(
            message => message?.text === selectedMessage?.value
        );

        if (!isSelectedMessagePresent) {
            updatedMessage.push({
                text: selectedMessage?.value,
                selected: true
            });
        }
        const updatedGermanMessages = germanMessageOptions?.map(option => ({
            text: option?.value,
            selected: option?.value === selectedGermanMessage?.value  // Set selected to true for the selected message
        }));

        const isSelectedGermanMessagePresent = updatedGermanMessages?.some(
            message => message?.text === selectedGermanMessage?.value
        );

        if (!isSelectedGermanMessagePresent) {
            updatedGermanMessages.push({
                text: selectedGermanMessage?.value,
                selected: true
            });
        }

        if (Object.keys(newErrors).length === 0) {
            try {
                const updatedForm = {
                    ...form,
                    message: updatedMessage,       // Pass filtered message with only 'text' field of selected ones
                    g_message: updatedGermanMessages // Pass filtered German message with only 'text' field of selected ones
                };

                const response = await updateNotification(updatedForm, { id });
                if (response?.error) {
                    setError(response.error);
                } else {
                    setError('');
                    setShowSuccessModal(true);
                    setForm({
                        created_by: user?.id,
                        title: '',
                        g_title: '',
                        g_message: [],
                        message: [],
                        time: ''
                    });
                }

            } catch (error) {
                console.error("Error submitting notification:", error);
            }
        } else {
            setErrors(newErrors);
        }
        setLoading(false);
    };

    const handleMessageChange = (newValue) => {
        setSelectedMessage(newValue);
        setForm(prevForm => ({
            ...prevForm,
            message: newValue ? [{ text: newValue.value, selected: true }] : []
        }));

        // Clear the error if a value is selected
        if (newValue) {
            setErrors(prevErrors => ({
                ...prevErrors,
                message: null
            }));
        }
    };

    const handleGermanMessageChange = (newValue) => {
        setSelectedGermanMessage(newValue);
        setForm(prevForm => ({
            ...prevForm,
            g_message: newValue ? [{ text: newValue.value, selected: true }] : []
        }));

        // Clear the error if a value is selected
        if (newValue) {
            setErrors(prevErrors => ({
                ...prevErrors,
                g_message: null
            }));
        }
    };

    return (
        <div className='mt-sm-4 mt-2 bg-white p-sm-4 p-2 rounded-3'>
            {loading && <Loading />}
            <Form>
                <div className={style.formsection}>
                    <div className='d-lg-flex justify-content-between'>
                        <Form.Group controlId='title'>
                            <Form.Label className={`${style.modelcontent}`}>Title :</Form.Label>
                            <Form.Control
                                type="text"
                                className={`${style.inputfield} form-control mt-sm-2 mt-1`}
                                value={form?.title || ''}
                                onChange={(e) => setField("title", e.target.value)}
                                placeholder='Enter title'
                            />
                            {errors.title && <div className="text-danger mt-2">{errors.title}</div>}
                        </Form.Group>
                        <Form.Group controlId='g_title' className='m-lg-0 mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>German Title :</Form.Label>
                            <Form.Control
                                type="text"
                                className={`${style.inputfield} form-control mt-sm-2 mt-1`}
                                value={form?.g_title || ''}
                                onChange={(e) => setField("g_title", e.target.value)}
                                placeholder='Enter German title'
                            />
                            {errors.g_title && <div className="text-danger mt-2">{errors.g_title}</div>}
                        </Form.Group>
                    </div>

                    <div className='d-lg-flex justify-content-between mt-4 mb-4'>
                        <Form.Group controlId='message' className='m-lg-0 mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Message :</Form.Label>
                            <CreatableSelect
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: '80px !important',
                                        borderColor: 'transparent',
                                        boxShadow: 'none',
                                    }),
                                }}
                                isClearable
                                className={`${style.inputfield} form-control mt-sm-2 mt-1 p-0`}
                                value={selectedMessage}
                                options={messageOptions}
                                onChange={handleMessageChange} // Handle the message selection
                            />
                            {errors.message && <div className="text-danger mt-2">{errors.message}</div>}
                        </Form.Group>

                        <Form.Group controlId='g_message' className='m-lg-0 mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>German Message :</Form.Label>
                            <CreatableSelect
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: '80px !important',
                                        borderColor: 'transparent',
                                        boxShadow: 'none',
                                    }),
                                }}
                                isClearable
                                className={`${style.inputfield} form-control mt-sm-2 mt-1 p-0`}
                                value={selectedGermanMessage}
                                options={germanMessageOptions}
                                onChange={handleGermanMessageChange} // Handle the German message selection
                            />
                            {errors.g_message && <div className="text-danger mt-2">{errors.g_message}</div>}
                        </Form.Group>
                    </div>

                    {/* Time field */}
                    {!(data?.day === "everyday" || data.type === "Special Events & Content") && (
                        <Form.Group controlId='time' className='m-lg-0 mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Time :</Form.Label>
                            <Form.Control
                                type="time"
                                className={`${style.inputfield} form-control mt-sm-2 mt-1`}
                                value={form?.time || ''}
                                onChange={(e) => setField("time", e.target.value)}
                                placeholder='Enter time'
                            />
                            {errors.time && <div className="text-danger mt-2">{errors.time}</div>}
                        </Form.Group>
                    )}
                    {(data?.day === "1st Sunday" && data.type === "Special Events & Content") && (
                        <Form.Group controlId='time' className='m-lg-0 mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Time :</Form.Label>
                            <Form.Control
                                type="time"
                                className={`${style.inputfield} form-control mt-sm-2 mt-1`}
                                value={form?.time || ''}
                                onChange={(e) => setField("time", e.target.value)}
                                placeholder='Enter time'
                            />
                            {errors.time && <div className="text-danger mt-2">{errors.time}</div>}
                        </Form.Group>
                    )}
                </div>

                {error && <div className="text-danger">{error}</div>}
                <div className='d-flex gap-3 mt-5'>
                    <Button className={`${style.cancelbutton}`} onClick={handleOnClose}>
                        Cancel
                    </Button>
                    <Button className={`${style.submitbutton}`} onClick={handleOnSubmit}>
                        Submit
                    </Button>
                </div>
            </Form>

            <Modal size='sm' show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered backdrop='static' keyboard={false} contentClassName='gap-2'>
                <Modal.Header className='border-0 p-0 justify-content-center'>
                    <img src={success} alt="Success" style={{ height: '120px' }} />
                </Modal.Header>
                <Modal.Body className='p-0'><h1 className={`${style.modelcontent} text-center`}>Notification updates successfully.</h1></Modal.Body>
                <Modal.Footer className='py-2 px-0 border-0 justify-content-center'>
                    <Button className={style.submitbutton} onClick={() => { setShowSuccessModal(false); navigate('/notifications') }}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default NotificationUpdateForm;
