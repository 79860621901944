import React, { useEffect, useState } from 'react'
import Loading from '../loading/Loading';
import style from './notification.module.css'
import { Tab, Table, Tabs } from 'react-bootstrap';
import { getNotifications } from '../../services/NotificationService';
import { FiEdit } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const NotificationView = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeKey, setActiveKey] = useState('english');
    const navigate = useNavigate();

    useEffect(() => {
        getNotificationData();
    }, []);

    const getNotificationData = async () => {
        try {
            setLoading(true);
            const response = await getNotifications();
            setData(response?.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const getSelectedMessage = (messages) => {
        const selectedMessage = messages.find(message => message.selected);
        return selectedMessage ? selectedMessage.text : '-';
    };

    // Group notifications by `type`
    const groupByType = (notifications) => {
        return notifications.reduce((acc, record) => {
            if (!acc[record.type]) {
                acc[record.type] = [];
            }
            acc[record.type].push(record);
            return acc;
        }, {});
    };

    const groupedData = groupByType(data);

    return (
        <>
            {loading && <Loading />}
            <Tabs
                id="fill-tab-example"
                activeKey={activeKey}
                onSelect={(k) => setActiveKey(k)}
                className="mb-3 border-0 mt-sm-4 mt-2"
            >
                <Tab eventKey="english" title={<span className={activeKey === 'english' ? style.activeTab : ''}>English</span>}>
                    {
                        // Loop through each type (e.g., "morning", "afternoon", etc.) and render a table for each
                        Object.keys(groupedData).map((type, typeIndex) => (
                            <div key={typeIndex}>
                                <h4 className='mt-4'>{type.charAt(0).toUpperCase() + type.slice(1)}</h4> {/* Capitalize first letter of type */}
                                <div id="table-container" className={`${style.tableContainer} mt-2`}>
                                    <Table responsive borderless>
                                        <thead>
                                            <tr className={`${style.rowheading} ${style.section1}`}>
                                                <th style={{ paddingLeft: '25px' }}>Day</th>
                                                <th>Title</th>
                                                <th>Message</th>
                                                <th>Time</th>
                                                <th className='text-center'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className={style.tabledata}>
                                            {
                                                groupedData[type]?.length > 0 ? groupedData[type].map((record, index) => (
                                                    <tr key={index}>
                                                        <td style={{ paddingLeft: '25px' }}>{record?.day ? record?.day : '-'}</td>
                                                        <td>{record?.title ? record?.title : '-'}</td>
                                                        <td>{getSelectedMessage(record?.message)}</td>
                                                        <td>{record?.time ? record?.time : '-'}</td>
                                                        <td>
                                                            <div className='d-flex justify-content-center gap-3'>
                                                                <FiEdit className={style.edit} onClick={() => navigate(`/notifications/edit-notification/${record?._id}`)} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )) :
                                                    <tr className='text-center'>
                                                        <td colSpan={5}><h4 className='p-4'>Data Not Found</h4></td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        ))
                    }
                </Tab>
                <Tab eventKey="german" title={<span className={activeKey === 'german' ? style.activeTab : ''}>German</span>}>
                    {
                        // Loop through each type (e.g., "morning", "afternoon", etc.) and render a table for each
                        Object.keys(groupedData).map((type, typeIndex) => (
                            <div key={typeIndex}>
                                <h4 className='mt-4'>{type.charAt(0).toUpperCase() + type.slice(1)}</h4> {/* Capitalize first letter of type */}
                                <div id="table-container" className={`${style.tableContainer} mt-2`}>
                                    <Table responsive borderless>
                                        <thead>
                                            <tr className={`${style.rowheading} ${style.section1}`}>
                                                <th style={{ paddingLeft: '25px' }}>Day</th>
                                                <th>Title</th>
                                                <th>Message</th>
                                                <th>Time</th>
                                                <th className='text-center'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className={style.tabledata}>
                                            {
                                                groupedData[type]?.length > 0 ? groupedData[type].map((record, index) => (
                                                    <tr key={index}>
                                                        <td style={{ paddingLeft: '25px' }}>{record?.day ? record?.day : '-'}</td>
                                                        <td>{record?.g_title ? record?.g_title : '-'}</td>
                                                        <td>{getSelectedMessage(record?.g_message)}</td>
                                                        <td>{record?.time ? record?.time : '-'}</td>
                                                        <td>
                                                            <div className='d-flex justify-content-center gap-3'>
                                                                <FiEdit className={style.edit} onClick={() => navigate(`/notifications/edit-notification/${record?._id}`)} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )) :
                                                    <tr className='text-center'>
                                                        <td colSpan={5}><h4 className='p-4'>Data Not Found</h4></td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        ))
                    }
                </Tab>
            </Tabs>
        </>
    )
}

export default NotificationView;
