import React, { useEffect } from 'react'
import NotificationView from '../components/notifications/NotificationView'

const Notification = ({ setHeading }) => {
    useEffect(() => {
        setHeading('Notifications')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <NotificationView />
                </div>
            </div>
        </>
    )
}

export default Notification;