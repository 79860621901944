import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { UserProvider } from './contexts/userContext';
import './App.css';
import PrivateRoutes from './components/privateroute/PrivateRoute';
import SideBar from './components/sidebar/SideBar';
import TransformMindPods from './pages/TransformMindPods';
import MotivationalMsg from './pages/MotivationalMsg';
import AddPods from './pages/AddPods';
import AddMotivationalMsg from './pages/AddMotivationalMsg';
import EditMotivationalMsg from './pages/EditMotivationalMsg';
import EditPods from './pages/EditPods';
import FocusAffirmationCategories from './pages/FocusAffirmationCategories';
import AddCategory from './pages/AddCategory';
import EditCategory from './pages/EditCategory';
import Affirmation from './pages/Affirmation';
import FAQ from './pages/FAQ';
import EditAffirmation from './pages/EditAffirmation';
import EditFAQ from './pages/EditFAQ';
import Contactsupport from './pages/Contactsupport';
import TroubleshootingGuides from './pages/TroubleshootingGuides';
import EditGuides from './pages/EditGuides';
import PrivacyPolicy from './pages/PrivacyPolicy';
import EditPrivacyPolicy from './pages/EditPrivacyPolicy';
import Dashboard from './pages/Dashboard';
import AddAffirmation from './pages/AddAffirmation';
import AddFAQ from './pages/AddFAQ';
import LoginAd from './pages/Login';
import { useState } from 'react';
import ImageText from './pages/ImageText';
import Notification from './pages/Notification';
import NotificationUpdateForm from './components/notifications/NotificationUpdateForm';

function App() {
  const [heading, setHeading] = useState('')

  return (
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginAd />} />
          <Route exec element={<PrivateRoutes />}>
            <Route element={<SideBar heading={heading} />}>
              <Route path="/" element={<Dashboard setHeading={setHeading} />} />
              <Route path="/focuses-affirmation-category" element={<FocusAffirmationCategories setHeading={setHeading} />} />
              <Route path="/focuses-affirmation-category/add-category" element={<AddCategory setHeading={setHeading} />} />
              <Route path="/focuses-affirmation-category/edit-category/:id" element={<EditCategory setHeading={setHeading} />} />
              <Route path="/affirmation" element={<Affirmation setHeading={setHeading} />} />
              <Route path="/affirmation/add-affirmation" element={<AddAffirmation setHeading={setHeading} />} />
              <Route path="/affirmation/edit-affirmation/:id" element={<EditAffirmation setHeading={setHeading} />} />
              <Route path="/transformyourmind-pods" element={<TransformMindPods setHeading={setHeading} />} />
              <Route path="/transformyourmind-pods/add-pods" element={<AddPods setHeading={setHeading} />} />
              <Route path="/transformyourmind-pods/edit-pods/:id" element={<EditPods setHeading={setHeading} />} />
              <Route path="/motivational-messages" element={<MotivationalMsg setHeading={setHeading} />} />
              <Route path="/motivational-messages/add-motivationalmsg" element={<AddMotivationalMsg setHeading={setHeading} />} />
              <Route path="/motivational-messages/edit-motivationalmsg/:id" element={<EditMotivationalMsg setHeading={setHeading} />} />
              <Route path="/faq" element={<FAQ setHeading={setHeading} />} />
              <Route path="/faq/add" element={<AddFAQ setHeading={setHeading} />} />
              <Route path="/faq/edit/:id" element={<EditFAQ setHeading={setHeading} />} />
              <Route path="/contact-support" element={<Contactsupport setHeading={setHeading} />} />
              <Route path="/troubleshhooting-guides" element={<TroubleshootingGuides setHeading={setHeading} />} />
              <Route path="/troubleshhooting-guides/edit-guides" element={<EditGuides setHeading={setHeading} />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy setHeading={setHeading} />} />
              <Route path="/privacy-policy/edit" element={<EditPrivacyPolicy setHeading={setHeading} />} />
              <Route path="/image-text" element={<ImageText setHeading={setHeading} />} />
              <Route path="/notifications" element={<Notification setHeading={setHeading} />} />
              <Route path="/notifications/edit-notification/:id" element={<NotificationUpdateForm setHeading={setHeading} />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;