import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import style from './podsform.module.css'
import ReactSelect from 'react-select';
import { BsUpload } from 'react-icons/bs';
import success from '../../assets/Success.png'
import { getCategory } from '../../services/CategoryService';
import { addPod, updatePod } from '../../services/TransFormPodsService';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../loading/Loading';

const PodsForm = ({ initialData }) => {
    const user = JSON.parse(localStorage.getItem('userdata'));
    const [errors, setErrors] = useState({ name: '', description: '', category: '', image: '', expertName: '' });
    const [form, setForm] = useState({ name: '', lang: 'english', selfHypnotic: false, g_name: '', g_description: '', description: '', category: '', audioFile: '', status: true, image: '', pods_by: false, expertName: '', isPaid: false });
    const [selectedFile, setSelectedFile] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const MAX_FILE_SIZE_MB = 1;
    const [error, setError] = useState('');
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        setErrors({ name: '', selfHypnotic: false, description: '', category: '', image: '', expertName: '' });
    }, [])

    useEffect(() => {
        setLoading(true)
        if (initialData) {
            setForm({
                name: initialData?.name,
                g_name: initialData?.g_name,
                g_description: initialData?.g_description,
                description: initialData?.description,
                status: initialData?.status,
                category: initialData?.category,
                audioFile: initialData?.audioFile,
                image: initialData?.image,
                pods_by: initialData?.pods_by,
                expertName: initialData?.expertName,
                isPaid: initialData?.isPaid,
                selfHypnotic: initialData?.selfHypnotic,
                lang: initialData?.lang,
            });
            setIsEditMode(true);
        }
        setLoading(false)
    }, [initialData]);

    const setField = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            top: 'auto',
            left: 'auto',
            right: '0',
            bottom: 'auto',
            marginTop: '10px',
            zIndex: 9999,
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: '80px',
            border: '1px solid #B8B8B8',
            '&:hover': {
                borderRadius: '80px',
                border: '1px solid #B8B8B8',
            },
        }),
        menuList: (provided, state) => ({
            ...provided,
            border: '1px solid #BDBDBD',
            borderRadius: '10px',
            backgroundColor: `#FFFFFF`,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isHovered ? '#769AA3' : '#FFFFFF',
            color: state.isHovered ? '#FFFFFF' : '#769AA3',
            padding: '5px',
            '&:hover': {
                backgroundColor: '#769AA3',
                color: '#FFFFFF',
            },
        }),
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setField('audioFile', event.target.value);

        if (selectedFile) {
            const allowedFileType = 'audio/mpeg';

            if (selectedFile.type !== allowedFileType) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    audioFile: "Please upload a valid MP3 file.",
                }));
                return;
            }

            setField('audioFile', selectedFile);
            setSelectedFile(selectedFile);

            setErrors((prevErrors) => ({
                ...prevErrors,
                audioFile: '',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                audioFile: "No file selected.",
            }));
        }
    };

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        if (selectedImage) {
            const fileSizeMB = selectedImage.size / (1024 * 1024);
            const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];

            if (!allowedFileTypes.includes(selectedImage.type)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    image: "Please upload a valid image file type (JPEG, PNG, GIF, BMP, WEBP).",
                }));
                return;
            }

            if (fileSizeMB > MAX_FILE_SIZE_MB) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    image: `File size exceeds ${MAX_FILE_SIZE_MB} MB.`,
                }));
                return;
            }

            setField('image', selectedImage);
            setSelectedImage(selectedImage);
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            image: '',
        }));
    };

    const validateForm = () => {
        const { name, description, category, image, expertName, g_name, g_description, lang } = form;

        const newErrors = {};
        if (lang === "english" || lang === "both") {
            if (!name || name === "") newErrors.name = "The transformyourmind pod name field is required";
        }

        if (lang === "german" || lang === "both") {
            if (!g_name || g_name === "") newErrors.g_name = "Das Feld „TransformYourMind-Podname“ ist erforderlich.";
        }
        if (lang === "english" || lang === "both") {
            if (!description || description === "") newErrors.description = "The description field is required.";
        }

        if (lang === "german" || lang === "both") {
            if (!g_description || g_description === "") newErrors.g_description = "Das Beschreibungsfeld ist erforderlich.";
        }

        if (!image || image === "") newErrors.image = "Please upload a file in suggested format.";
        if (!category || category === "") newErrors.category = "The select category  field is required.";
        if (form?.pods_by === 1) {
            if (!expertName || expertName === "") newErrors.expertName = "The expert name field is required.";
        }
        return newErrors;
    };

    const handleOnClose = () => {
        setForm({})
        setErrors({})
        navigate('/transformyourmind-pods')
    }
    console.log(form?.selfHypnotic !== false || form?.isPaid !== false)
    const handleOnSubmit = async () => {
        const newErrors = validateForm();
        setLoading(true)
        if (Object.keys(newErrors).length === 0) {
            try {
                const formData = new FormData();
                formData.append('created_by', user?.id);

                if (form.lang === 'english') {
                    formData.append('name', form.name || '');
                    formData.append('g_name', '');
                    formData.append('description', form.description || '');
                    formData.append('g_description', '');
                } else if (form.lang === 'german') {
                    formData.append('name', '');
                    formData.append('g_name', form.g_name || '');
                    formData.append('description', '');
                    formData.append('g_description', form.g_description || '');
                } else if (form.lang === 'both') {
                    formData.append('name', form.name || '');
                    formData.append('g_name', form.g_name || '');
                    formData.append('description', form.description || '');
                    formData.append('g_description', form.g_description || '');
                }

                formData.append('image', form.image || '');
                formData.append('category', form.category || '');
                formData.append('audioFile', form.audioFile || '');
                formData.append('isPaid', form.isPaid || false);
                formData.append('lang', form.lang || '');

                if (form.status === false) {
                    formData.append('status', false);
                } else {
                    formData.append('status', true);
                }

                if (form.pods_by === false) {
                    formData.append('pods_by', false);
                } else {
                    formData.append('pods_by', true);
                }

                if (form?.pods_by === true) {
                    formData.append('expertName', form?.expertName);
                } else {
                    formData.append('expertName', '');
                }

                if (form?.isPaid !== false) {
                    formData.append('selfHypnotic', form?.selfHypnotic);

                } else {
                    formData.append('selfHypnotic', false);
                }

                if (isEditMode) {
                    const response = await updatePod(formData, { id });
                    if (response?.error) {
                        setError(response.error);
                    } else {
                        setError('');
                        setShowSuccessModal(true);
                    }
                }
                else {
                    const response = await addPod(formData);
                    if (response?.error) {
                        setError(response.error);
                    } else {
                        setError('');
                        setShowSuccessModal(true);
                        setForm({});
                        setSelectedImage('')
                    }
                }
            } catch (error) {
                console.error("Error submitting pods:", error);
            }
        } else {
            setErrors(newErrors);
        }
        setLoading(false)
    };

    useEffect(() => {
        getCategoryData();
    }, []);

    const params = {
        created_by: user?.id,
        type: 0,
        isAdmin: true
    };

    const getCategoryData = async () => {
        try {
            const response = await getCategory(params);
            setCategoryData(response?.data);
        } catch (error) {
            console.error(error);
        }
    };

    const languages = [
        { label: 'English', value: 'english' },
        { label: 'German', value: 'german' },
        { label: 'Both', value: 'both' }
    ];

    const statuses = [
        { label: 'Active', value: true },
        { label: 'In Active', value: false }
    ];

    return (
        <div className='mt-sm-4 mt-2 bg-white p-sm-4 p-2 rounded-3'>
            {loading && <Loading />}
            <Form>
                <div className='d-lg-flex gap-3' >
                    <div className={style.formsection}>
                        <Form.Group controlId='lang' className='mb-4 d-flex align-items-center gap-3'>
                            <Form.Label className={`${style.modelcontent}`}>Language :</Form.Label>
                            <div className='d-flex gap-3'>
                                {languages.map((language) => (
                                    <Form.Check
                                        key={language.value}
                                        type="radio"
                                        className={`${style.radiobutton}`}
                                        aria-label={`Radio button for ${language.label}`}
                                        label={language.label}
                                        name="lang"
                                        value={language.value}
                                        checked={form.lang === language.value}
                                        onChange={(e) => setField('lang', e.target.value)}
                                        id={language.value}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                        <Form.Group controlId='category' >
                            <Form.Label className={`${style.modelcontent}`}>Select Category :</Form.Label>
                            <ReactSelect
                                aria-label="Default select example"
                                className={`${style.inputfieldselect} mt-sm-3 mt-1`}
                                styles={customStyles}
                                options={[
                                    ...categoryData?.map((record) => ({
                                        value: record?.name,
                                        label: `${record?.name}  ${record?.g_name ? '/' + record?.g_name : ''}`,
                                    }))
                                ]}
                                value={{ value: form?.category, label: form?.category ? form?.category : 'Select Category' }}
                                onChange={(selectedOption) => setField('category', selectedOption?.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.category && <div className="text-danger mt-2">{errors.category}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className='d-lg-flex justify-content-between'>
                            {['english', 'both'].includes(form?.lang) && (
                                <Form.Group controlId='name' className='mt-4'>
                                    <Form.Label className={`${style.modelcontent}`}>TransformYourMind Pod Name :</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={`${style.inputfield} form-control mt-sm-3 mt-1`}
                                        value={form?.name}
                                        onChange={(e) => setField("name", e.target.value)}
                                        placeholder='Enter transformyourmind pod name'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name && <div className="text-danger mt-2">{errors.name}</div>}
                                    </Form.Control.Feedback>
                                </Form.Group>)}
                            {['german', 'both'].includes(form?.lang) && (
                                <Form.Group controlId='g_name' className='mt-4'>
                                    <Form.Label className={`${style.modelcontent}`}>Name des Transformyourmind-Pods</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={`${style.inputfield} form-control mt-sm-3 mt-1`}
                                        value={form?.g_name}
                                        onChange={(e) => setField("g_name", e.target.value)}
                                        placeholder='Geben Sie den Namen des Transformyourmind-Pods ein.'
                                    />
                                </Form.Group>)}
                        </div>
                        <div className='d-lg-flex justify-content-between'>
                            {['english', 'both'].includes(form?.lang) && (
                                <Form.Group controlId='description' className='mt-4'>
                                    <Form.Label className={`${style.modelcontent}`}>Description :</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        className={`${style.inputfieldtextarea} form-control mt-sm-3 mt-1`}
                                        value={form?.description}
                                        onChange={(e) => setField("description", e.target.value)}
                                        placeholder='Enter description'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description && <div className="text-danger mt-2">{errors.description}</div>}
                                    </Form.Control.Feedback>
                                </Form.Group>)}
                            {['german', 'both'].includes(form?.lang) && (
                                <Form.Group controlId='g_description' className='mt-4'>
                                    <Form.Label className={`${style.modelcontent}`}>Beschreibung :</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        className={`${style.inputfieldtextarea} form-control mt-sm-3 mt-1`}
                                        value={form?.g_description}
                                        onChange={(e) => setField("g_description", e.target.value)}
                                        placeholder='Beschreibung eingeben'
                                    />
                                </Form.Group>)}
                        </div>
                        <Form.Group controlId="image" className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>TransformYourMind Pod Image :</Form.Label>
                            <Form.Control
                                type="file"
                                size="sm"
                                className="visually-hidden"
                                onChange={(e) => handleImageChange(e)}
                                isInvalid={!!errors.file}
                            />
                            <div className={`input-group mt-sm-3 mt-1`}>
                                <label htmlFor="image" className={`btn btn-primary input-group-text ${style.fileupload}`}>
                                    <div className={`d-flex flex-column align-items-center ${style.filename}`}>
                                        <BsUpload />
                                    </div>
                                </label>
                            </div>
                            {form?.image && (
                                <div className={`${style.imagedisplay} d-flex gap-3 mt-3 p-2`}>
                                    <img
                                        src={selectedImage ? URL.createObjectURL(selectedImage) : `${form?.image}`}
                                        alt="Selected Pod Image"
                                        style={{ width: '85px', height: '50px', borderRadius: '6px' }}
                                    />
                                    {
                                        <div>
                                            <p className='mb-0'>{selectedImage ? selectedImage.name : form?.image.split('/').pop()}</p>
                                            {selectedImage && <p>{(selectedImage.size / (1024 * 1024)).toFixed(2)} MB</p>}
                                        </div>
                                    }
                                </div>
                            )}
                            <Form.Control.Feedback type="invalid">
                                {errors.image && <div className="text-danger mt-2">{errors.image}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="audioFile" className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Upload Alarms Audio :</Form.Label>
                            <Form.Control
                                type="file"
                                size="sm"
                                className="visually-hidden"
                                onChange={(e) => handleFileChange(e)}
                                isInvalid={!!errors.file}
                            />
                            <div className={`input-group mt-sm-3 mt-1`}>
                                <label htmlFor="audioFile" className={`btn btn-primary input-group-text ${style.fileupload}`}>
                                    <div className={`d-flex flex-column align-items-center ${style.filename}`}>
                                        <BsUpload />
                                        {selectedFile ? selectedFile?.name : form?.audioFile?.split('/').pop()}
                                    </div>
                                </label>
                            </div>
                        </Form.Group>
                        <Form.Group controlId='isPaid' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Paid :</Form.Label>
                            <div className='mt-sm-3 mt-1 d-flex gap-3'>
                                <Form.Check
                                    type="radio"
                                    className={`${style.radiobutton}`}
                                    aria-label="Checkbox for following text input"
                                    label="Yes"
                                    name="isPaid"
                                    value={true}
                                    checked={form.isPaid === true}
                                    onChange={(e) => setField('isPaid', true)}
                                    id='paid'
                                />
                                <Form.Check
                                    type="radio"
                                    className={`${style.radiobutton}`}
                                    aria-label="Checkbox for following text input"
                                    label="No"
                                    name="isPaid"
                                    value={false}
                                    checked={form.isPaid === false}
                                    onChange={(e) => setField('isPaid', false)}
                                    id='free'
                                />
                            </div>
                        </Form.Group>
                        {
                            form?.isPaid && <>
                                <Form.Group controlId='selfHypnotic' className='mt-4'>
                                    <Form.Label className={`${style.modelcontent}`}>Is Selfhypnotic :</Form.Label>
                                    <div className='mt-sm-3 mt-1 d-flex gap-3'>
                                        <Form.Check
                                            type="radio"
                                            className={`${style.radiobutton}`}
                                            aria-label="Checkbox for following text input"
                                            label="Yes"
                                            name="selfHypnotic"
                                            value={true}
                                            checked={form.selfHypnotic === true}
                                            onChange={(e) => setField('selfHypnotic', true)}
                                            id='yes'
                                        />
                                        <Form.Check
                                            type="radio"
                                            className={`${style.radiobutton}`}
                                            aria-label="Checkbox for following text input"
                                            label="No"
                                            name="selfHypnotic"
                                            value={false}
                                            checked={form.selfHypnotic === false}
                                            onChange={(e) => setField('selfHypnotic', false)}
                                            id='no'
                                        />
                                    </div>
                                </Form.Group>
                            </>
                        }
                        <Form.Group controlId='status' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Status :</Form.Label>
                            <div className='mt-sm-3 mt-1 d-flex gap-3'>
                                {statuses.map((status) => (
                                    <Form.Check
                                        key={status.value}
                                        type="radio"
                                        className={`${style.radiobutton}`}
                                        aria-label={`Radio button for ${status.label}`}
                                        label={status.label}
                                        name="status"
                                        value={status.value}
                                        checked={form.status === status.value}
                                        onChange={() => setField('status', status.value)}
                                        id={status.label.toLowerCase().replace(' ', '')}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                        <Form.Group controlId='type' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>TransformYourMind Pods By :</Form.Label>
                            <div className='mt-sm-3 mt-1 d-flex gap-3'>
                                <Form.Check
                                    type="radio"
                                    className={`${style.radiobutton}`}
                                    aria-label="Checkbox for following text input"
                                    label="TransformYourMind"
                                    name="pods_by"
                                    value={false}
                                    checked={form.pods_by === false}
                                    onChange={(e) => setField('pods_by', false)}
                                    id='transformYourMind'
                                />
                                <Form.Check
                                    type="radio"
                                    className={`${style.radiobutton}`}
                                    aria-label="Checkbox for following text input"
                                    label="Expert"
                                    name="pods_by"
                                    value={true}
                                    checked={form.pods_by === true}
                                    onChange={(e) => setField('pods_by', true)}
                                    id='expert'
                                />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                {errors.pods_by && <div className="text-danger mt-2">{errors.pods_by}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        {
                            form?.pods_by === true &&
                            <>
                                <Form.Group controlId='expertName' className='mt-4'>
                                    <Form.Label className={`${style.modelcontent}`}>Expert Name :</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={`${style.inputfield} form-control mt-sm-3 mt-1`}
                                        value={form?.expertName}
                                        onChange={(e) => setField("expertName", e.target.value)}
                                        placeholder='Enter expert name'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.expertName && <div className="text-danger mt-2">{errors.expertName}</div>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </>
                        }


                    </div>
                </div>
                {error && <div className="text-danger mt-4">{error}</div>}
                <div className='d-flex justify-content-center gap-3  mt-4'>
                    <Button className={`${style.cancelbutton}`} onClick={handleOnClose}>
                        Cancel
                    </Button>
                    <Button className={`${style.submitbutton}`} onClick={handleOnSubmit}>
                        {isEditMode ? 'Update' : 'Submit'}
                    </Button>
                </div>
            </Form>
            <Modal size='sm' show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered backdrop='static' keyboard={false} contentClassName='gap-2'>
                <Modal.Header className='border-0 p-0 justify-content-center'>
                    <img src={success} alt="Success" style={{ height: '120px' }} />
                </Modal.Header>
                <Modal.Body className='p-0'><h1 className={`${style.modelcontent} text-center`}>You have successfully Created a new TransformYourMind pods</h1></Modal.Body>
                <Modal.Footer className='py-2 px-0 border-0 justify-content-center'>
                    <Button className={style.submitbutton} onClick={() => { setShowSuccessModal(false); navigate('/transformyourmind-pods') }}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default PodsForm